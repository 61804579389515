<template>
    <div>
      <ProjectDetailItemEditCard confirmation-message="Contact informatie werd succesvol gewijzigd"
        title="Bewerk contact informatie" :projectId="projectId" :clientId="clientId" 
        update-store-action="projectsModule/updateContactInformation" :update-store-action-payload="{clientId: clientId, projectId: projectId, contactInformation: contactInformation}"
        detail-view-route-location=""
        >
        <template v-slot:form-fields>
          <v-row>
            <v-col>
              <EditExternalContacts v-for="(contact, index) in contactInformation.contactList" v-model="contactInformation.contactList[index]" v-bind:key="index"/>
            </v-col>
          </v-row>
        </template>
      </ProjectDetailItemEditCard>
    </div>
  </template>
  
  <script>
  import ProjectDetailItemEditCard from "@/views/project/forms/ProjectDetailItemEditCard.vue"
  import EditExternalContacts from "../../../components/shared/fields/EditExternalContacts.vue"
  
  
  export default {
    name: "ProjectDetailEditProjectInformation",
    props: {
      projectId: {
        type: String,
        required: true
      },
      clientId: {
        type: String,
        required: true
      }
    },
    components: {
      ProjectDetailItemEditCard,
      EditExternalContacts
    },
    data() {
      return {
        contactInformation: {
            contactList: [
                {
                    firstName: null,
                    lastName: null,
                    phone: null,
                    email: null,
                    primaryContact: null,
                    jobTitle: null
                }
            ]
        },
        promises: [
            this.$store.dispatch('projectsModule/fetchContactInformation', {clientId: this.clientId, projectId: this.projectId})
              .then((contactInformation) => {
                this.contactInformation = contactInformation
                if (!this.contactInformation.contactList) {
                    this.contactInformation.contactList = [{primaryContact: true}]
                }
              })
          ]
      }
    }
  }
  </script>
  